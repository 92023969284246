export default function ({ text }: { text: string }) {
  return (
    <>
      <div className="prompt-preview">
        <h3 style={{ marginTop: '0px', marginBotton: '1em' }}>Preview</h3>
        {text}
      </div>
    </>
  )
}

import './index.css'
import './scaffold.css'

import { Box, Grid, grommet, Grommet } from 'grommet'
import { deepMerge } from 'grommet/utils'

import { AuthProvider } from '@redwoodjs/auth'
import WebAuthnClient from '@redwoodjs/auth/webAuthn'
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import AppHeader from './components/AppHeader/AppHeader'

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: '#333333',
    },
  },
})

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider
        type="dbAuth"
        config={{ fetchConfig: { credentials: 'include' } }}
      >
        <RedwoodApolloProvider
          graphQLClientConfig={{ httpLinkConfig: { credentials: 'include' } }}
        >
          <Grommet theme={theme} className=".rw-scaffold" full>
            <Routes />
          </Grommet>
        </RedwoodApolloProvider>
      </AuthProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
)

export default App

import './AppHeader.css'

import { useContext } from 'react'

import { Box, Button, Header, Nav } from 'grommet'

import { useAuth } from '@redwoodjs/auth'
import { Link, matchPath, routes, useLocation } from '@redwoodjs/router'
import { LocationContext } from '@redwoodjs/router/dist/location'

import logo from './logo_tmp.png'

const useMatch = (route) => {
  const location = useContext(LocationContext)
  const matchInfo = matchPath(route, location.pathname)

  return matchInfo.match
}

const AppHeader = () => {
  const { currentUser, logOut } = useAuth()
  const { pathname } = useLocation()
  if (pathname === '/signup' || pathname === '/login') {
    return null
  }

  const doLogOut = () => {
    logOut()
  }
  return (
    <Header background="light-1" style={{ borderBottom: '1px solid black' }}>
      <Nav direction="row">
        <img
          height={'33px'}
          src={logo}
          alt="Logo"
          style={{ margin: '11px  0px 11px 11px' }}
        />
        <p style={{ marginTop: '16px' }}>Relativistic</p>
        <Box direction="row" align="center">
          <Link
            to={routes.query()}
            className={useMatch(routes.query()) ? 'active' : 'inactive'}
          >
            <Button label="Query" />
          </Link>
          <Link
            to={routes.context_basic()}
            className={useMatch(routes.context_basic()) ? 'active' : 'inactive'}
          >
            <Button label="Contexts" />
          </Link>
          <Link
            to={routes.history()}
            className={useMatch(routes.history()) ? 'active' : 'inactive'}
          >
            <Button label="History" />
          </Link>
          <Link to={routes.home()}>
            <Button label="Prompts" />
          </Link>
          <Link to={routes.home()}>
            <Button label="Team" />
          </Link>
        </Box>
      </Nav>
      <div className="account-dropdown circle" onClick={doLogOut}>
        <p className="text">{currentUser.firstName[0].toUpperCase()}</p>
      </div>
    </Header>
  )
}

export default AppHeader

// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage
import { useAuth } from '@redwoodjs/auth'
import { Private, Route, Router, Set } from '@redwoodjs/router'

import MainLayout from './layouts/MainLayout/MainLayout'
import QueryPage from './pages/QueryPage/QueryPage'

const Routes = () => {
  const { currentUser } = useAuth()
  return (
    <Router>
      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/signup" page={SignupPage} name="signup" />
      <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
      <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
      <Private unauthenticated="login">
        <Set wrap={MainLayout}>
          <Route path="/context" page={ContextPage} name="context_basic" />
          <Route path="/context/{id}" page={ContextPage} name="context_route" />
          <Route path="/" page={QueryPage} name="home" />
          <Route path="/query" page={QueryPage} name="query" />
          <Route path="/history" page={QueryHistoryPage} name="history" />
        </Set>
        <Route path="/chrome" page={ChromePage} name="chrome" />
      </Private>

      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes

import './QueryPage.css'

import { useCallback, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Box } from 'grommet'
import { Columns } from 'grommet-icons'

import { useAuth } from '@redwoodjs/auth'
import { parseSearch, useLocation } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'

import Exposures from 'src/components/Exposures/Exposures'
import debounce from 'src/utils/debounce'
import { runQueryBackground, runQuery } from 'src/utils/runQuery'

import PromptInput from './Components/PromptInput'
import PromptPreview from './Components/PromptPreview'
import PromptResponse from './Components/PromptResponse'

const QueryPage = () => {
  const { search } = useLocation()
  const queryStringParse = parseSearch(search)['prompt']
  const runImmediately = parseSearch(search)['runImmediately'] == 'true'
  const { currentUser } = useAuth()

  const [prompt, setPrompt] = useState(queryStringParse)
  const [response, setResponse] = useState(null)
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [exposures, setExposures] = useState([])
  const [preview, setPreview] = useState(queryStringParse)
  const [showPreview, setShowPreview] = useState(false)
  const [showContext, setShowContext] = useState(false)
  const client = useApolloClient()
  useEffect(() => {
    if (runImmediately && !window['hasRunImmediately']) {
      window['hasRunImmediately'] = true
      getResponseForPrompt(prompt)
    }
  })

  const debounceOnChange = useCallback(debounce(onChange, 400), [exposures])

  function onChange(prompt, exposures, doFake = true) {
    return fetch(`/.netlify/functions/renderTemplate?engine=great`, {
      method: 'POST',
      headers: {
        'auth-provider': 'dbAuth',
        authorization: `Bearer ${currentUser.id}`,
      },

      body: JSON.stringify({
        template: prompt,
        exposures: exposures.map((d) => ['local', d.title]),
        autoprefix: exposures.map((d) => ['local', d.title]),
      }),
    })
      .then((t) => t.json())
      .then((t) => t.data)
      .then(setPreview)
  }

  function getResponseForPrompt(prompt, fake = false) {
    setLoadingResponse(true)
    runQueryBackground(
      prompt,
      exposures.map((d) => ['local', d.title]),
      exposures.map((d) => ['local', d.title]),
      currentUser.id,
      client,
      ({ response, status }) => {
        setResponse(response)
        if (response.length || status != 'PROCESSING') setLoadingResponse(false)
      }
    )

    // if (!fake) {
    //   runQuery(
    //     prompt,
    //     exposures.map((d) => ['local', d.title]),
    //     exposures.map((d) => ['local', d.title]),
    //     currentUser.id
    //   )
    //     .then(setResponse)
    //     .then(() => {
    //       setLoadingResponse(false)
    //     })
    // } else {
    //   setTimeout(() => {
    //     setResponse('Mock Response')
    //     setLoadingResponse(false)
    //   }, 3000)
    // }
  }

  return (
    <Box direction="row" flex="grow">
      <MetaTags title="Prompt" description="Prompt page" />
      <div style={{ height: '100%', overflow: 'auto', flex: 1 }}>
        <Box
          direction="column"
          align="center"
          height={'fit-content'}
          className="editor-main"
        >
          <Box direction="column" align="center" width={'large'}>
            <Box direction="column" height={'fit-content'} width={'100%'}>
              <PromptInput
                starterPrompt={queryStringParse}
                updateShowPreview={setShowPreview}
                onEdit={(prompt) => {
                  setPrompt(prompt)
                  debounceOnChange(prompt, exposures)
                }}
                onSubmit={getResponseForPrompt}
                exposures={exposures}
              ></PromptInput>
              {showPreview ? (
                <PromptPreview text={preview}></PromptPreview>
              ) : (
                <></>
              )}
            </Box>

            <PromptResponse
              response={response}
              loadingResponse={loadingResponse}
            />
          </Box>
          <div className="control-icons">
            <Columns onClick={() => setShowContext(!showContext)}></Columns>
          </div>
        </Box>
      </div>

      {showContext ? (
        <Box
          className="context"
          direction="column"
          width={{ min: '240px', max: '240px' }}
        >
          <Exposures
            setExposures={(exposures) => {
              setExposures(exposures)
              debounceOnChange(prompt, exposures)
            }}
          ></Exposures>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default QueryPage

import './PromptInput.css'

import { useEffect, useRef, useState } from 'react'

import { Button, Footer } from 'grommet'
import { Contract, Expand, Send } from 'grommet-icons'

type PromptInputProps = {
  starterPrompt: string
  onSubmit: (text: string, fake: boolean) => void
  onEdit: (text: string) => void
  updateShowPreview: (show: boolean) => void
  exposures: any
}

export default function ({
  onSubmit,
  onEdit,
  starterPrompt,
  updateShowPreview,
}: PromptInputProps) {
  const [prompt, setPrompt] = useState(starterPrompt)
  const [ShowPreview, setShowPreview] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [inputRef])

  function handleKeyCapture(event) {
    if (event.key == 'Enter' && event.metaKey) onSubmit(prompt, false)
  }

  return (
    <form
      className="prompt-form"
      action=""
      onSubmit={() => onSubmit(prompt)}
      onKeyDownCapture={handleKeyCapture}
    >
      <div
        className="prompt-input"
        contentEditable={true}
        suppressContentEditableWarning={true}
        ref={inputRef}
        onInput={(event) => {
          setPrompt(event.currentTarget.textContent)
          onEdit(event.currentTarget.textContent)
        }}
      >
        {starterPrompt}
      </div>
      <Footer>Enter A Prompt Above</Footer>
      <div
        className={`preview-toggle ${ShowPreview ? 'active' : ''}`}
        onClick={() => {
          setShowPreview(!ShowPreview)
          updateShowPreview(!ShowPreview)
        }}
      >
        {ShowPreview ? (
          <Contract size={'small'} color="brand" />
        ) : (
          <Expand size={'small'} color="brand" />
        )}
      </div>
      <Button icon={<Send onClick={() => onSubmit(prompt)}></Send>}></Button>
      <Button icon={<Send onClick={() => onSubmit(prompt)}></Send>}></Button>
    </form>
  )
}

import { useState } from 'react'

import { Box, CheckBox, CheckBoxGroup } from 'grommet'
import { Ascend, Checkmark } from 'grommet-icons'

import './Exposure.css'

const Exposure = ({ data, setUsed }) => {
  const [checked, setChecked] = useState(false)
  return (
    <div className="exposure-cell">
      <Box direction="row" justify="between">
        <h4>{data.title}</h4>
        <CheckBox
          checked={checked}
          onClick={() => {
            setChecked(!checked)
            setUsed(!checked)
          }}
        >
          {({ checked }) => (
            <Checkmark color={checked ? 'green' : 'status-unknown'} />
          )}
        </CheckBox>
      </Box>
    </div>
  )
}

export default Exposure

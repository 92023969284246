const query = gql`
  query QueryHistory($id: String!) {
    queryHistory(id: $id) {
      id
      query
      response
      status
    }
  }
`

export function runQuery(template, exposures, autoprefix, userId) {
  return fetch(`/.netlify/functions/runQuery?engine=great`, {
    method: 'POST',
    headers: { 'auth-provider': 'dbAuth', authorization: 'Bearer ' + userId },
    body: JSON.stringify({
      template,
      exposures,
      autoprefix,
    }),
  })
    .then((t) => t.json())
    .then((t) => t.data.text)
}

export async function runQueryBackground(
  template,
  exposures,
  autoprefix,
  userId,
  client,
  cb
) {
  const qh = await fetch(`/.netlify/functions/beginQuery`, {
    method: 'POST',
    headers: { 'auth-provider': 'dbAuth', authorization: 'Bearer ' + userId },
    body: JSON.stringify({
      template,
      exposures,
      autoprefix,
    }),
  })
    .then((t) => t.json())
    .then((t) => t.data)

  await fetch(`/.netlify/functions/runQuery-background`, {
    method: 'POST',
    headers: { 'auth-provider': 'dbAuth', authorization: 'Bearer ' + userId },
    body: JSON.stringify({
      query: template,
      id: qh.id,
    }),
  })

  async function fetchUpdate(queryHistoryId) {
    const queryHistory = await client
      .query({
        query: query,
        variables: { id: queryHistoryId },
      })
      .then((d) => d.data.queryHistory)

    cb(queryHistory)

    if (queryHistory.status != 'COMPLETE') {
      setTimeout(() => {
        fetchUpdate(queryHistoryId)
      }, 250)
    }
  }

  fetchUpdate(qh.id)
}

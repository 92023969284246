import { Box, Header, Spinner } from 'grommet'

import Arrow from './Arrow'

export default function ({ response, loadingResponse }) {
  if (response || loadingResponse) {
    return (
      <>
        <Arrow width="44px" height="55px"></Arrow>
        <Box className="prompt-response-display" height={'auto'}>
          <Header>Response</Header>

          <div>{!loadingResponse ? response : <Spinner />}</div>
        </Box>
      </>
    )
  } else {
    return <div className="prompt-to-pompt">Run a prompt to get a result</div>
  }
}

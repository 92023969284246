export default function arrow(props) {
  const { width, height } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      preserveAspectRatio="none"
      width={width || '44px'}
      height={height || '44px'}
      viewBox="0 0 56 56"
    >
      <path d="M 27.9883 5.8633 C 26.7695 5.8633 25.9024 6.7071 25.9024 7.9258 L 25.9024 38.7930 L 26.0898 43.8086 L 19.0586 36.0976 L 13.7383 30.8476 C 13.3633 30.4727 12.8008 30.2851 12.2383 30.2851 C 11.0898 30.2851 10.2227 31.1758 10.2227 32.3242 C 10.2227 32.8867 10.4336 33.3789 10.8789 33.8476 L 26.4414 49.4336 C 26.8867 49.9024 27.4258 50.1367 27.9883 50.1367 C 28.5742 50.1367 29.1133 49.9024 29.5586 49.4336 L 45.1211 33.8476 C 45.5664 33.3789 45.7773 32.8867 45.7773 32.3242 C 45.7773 31.1758 44.9102 30.2851 43.7383 30.2851 C 43.1992 30.2851 42.6367 30.4727 42.2617 30.8476 L 36.9180 36.0976 L 29.9102 43.7852 L 30.0976 38.7930 L 30.0976 7.9258 C 30.0976 6.7071 29.2071 5.8633 27.9883 5.8633 Z" />
    </svg>
  )
}

import { useState } from 'react'

import { Box } from 'grommet'

import { useQuery } from '@redwoodjs/web'

import Exposure from './Exposure/Exposure'

const query = gql`
  query DatapointsQuery {
    datapoints {
      id
      title
      value
    }
  }
`
const Exposures = ({ setExposures }) => {
  const [injections, setInjections] = useState(new Set())
  const { data, loading } = useQuery(query)

  return (
    <Box direction="column">
      {loading ||
        data.datapoints.map((d) => {
          return (
            <Exposure
              key={d.id}
              data={d}
              setUsed={(isUsed) => {
                isUsed ? injections.add(d) : injections.delete(d)
                setInjections(injections)
                setExposures(Array.from(injections))
              }}
            ></Exposure>
          )
        })}
    </Box>
  )
}

export default Exposures

import { Box } from 'grommet'

import AppHeader from 'src/components/AppHeader/AppHeader'

type MainLayoutProps = {
  children?: React.ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <Box direction="column" basis="full" fill={true}>
      <AppHeader />
      <Box direction="row" gridArea="main" basis="full" fill={true}>
        {/* <div
          className="navbar"
          style={{
            width: '150px',
            borderRight: '1px solid black',
            height: '100%',
            display: 'flex',
          }}
        ></div> */}
        {children}
      </Box>
    </Box>
  )
}

export default MainLayout
